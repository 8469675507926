export enum OfferTemplateView {
  OFFER = "offer",
  CLOSING = "closing",
  BP_OFFER_DETAILS = "bp_offer_details",
  BP_CLOSING_DETAILS = "bp_closing_details"
}

export enum VisibilityControl {
  workflow_live = "workflow_live",
  borrower_platform = "borrower_platform"
}

export enum BpOfferSection {
  PRIMARY = "primary",
  EXPANDED = "expanded"
}
